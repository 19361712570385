<!-- 发起对账弹窗 -->
<template>
  <el-dialog
    width="30%"
    center
    :visible="visible"
    :lock-scroll="false"
    :destroy-on-close="true"
    custom-class="ele-dialog-form"
    :title="isUpdate?'发起对账':'发起对账'"
    @update:visible="updateVisible">
    <el-form
      ref="form"
      :model="form"
      label-width="120px">

      <el-form-item label="客户名称：">
        <span>柳新吉</span>
      </el-form-item>
      <el-form-item label="对账周期：">
        <el-date-picker
        unlink-panels
          v-model="form.value1"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="订单总量：">
        <span>78</span>
      </el-form-item>
      <el-form-item label="合同价：">
        <span>5000</span>
      </el-form-item>
      <el-form-item label="收款账户：">
        <el-select clearable v-model="form.value" placeholder="账号A,账户名称B,C银行">
          <el-option label="综合" value="1"/>
          <el-option label="综合1" value="2"/>
          <el-option label="综合2" value="3"/>
        </el-select>
        <el-button style="background: #3F4157!important;border-color: #3F4157!important;color: #FFFFFF!important;margin-left: 20px;">管理收款账户</el-button>
      </el-form-item>

    </el-form>


    <div slot="footer">
      <el-button
        @click="updateVisible(false)">取消
      </el-button>
      <el-button
        style="margin-left: 20px;"
        type="primary"
        :loading="loading"
        @click="save">确定
      </el-button>
    </div>

  </el-dialog>
</template>

<script>

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({status: 1}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {

  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.updateVisible(false);
          this.$emit('done');
          this.loading = false;
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },


  }
}
</script>

<style scoped lang="scss">

</style>
